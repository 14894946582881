/* Chinese initialisation for the jQuery UI date picker plugin. */
/* Written by SCCY (samuelcychan@gmail.com). */
( function( factory ) {
	if ( typeof define === "function" && define.amd ) {

		// AMD. Register as an anonymous module.
		define( [ "../widgets/datepicker" ], factory );
	} else {

		// Browser globals
		factory( jQuery.datepicker );
	}
}( function( datepicker ) {

datepicker.regional[ "zh-HK" ] = {
	closeText: "關閉",
	prevText: "上個",
	nextText: "下個",
	currentText: "今天",
	monthNames: [ "一月","二月","三月","四月","五月","六月",
	"七月","八月","九月","十月","十一月","十二月" ],
	monthNamesShort: [ "一月","二月","三月","四月","五月","六月",
	"七月","八月","九月","十月","十一月","十二月" ],
	dayNames: [ "星期日","星期一","星期二","星期三","星期四","星期五","星期六" ],
	dayNamesShort: [ "(日)","(一)","(二)","(三)","(四)","(五)","(六)" ],
	dayNamesMin: [ "日","一","二","三","四","五","六" ],
	weekHeader: "星期",
	// dateFormat: "dd-mm-yy",
	// firstDay: 0,
	// isRTL: false,
	// showMonthAfterYear: true,
    // yearSuffix: "年" 
};
datepicker.setDefaults( datepicker.regional[ "zh-HK" ] );

return datepicker.regional[ "zh-HK" ];

} ) );